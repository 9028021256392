import { createContext, Dispatch, useEffect, useReducer } from "react";

import { Session } from "../../interfaces";

type SessionLocal = Session & {
  cryptPrivateKey: string;
  signPrivateKey: string;
  artWalletMnemonic?: string;
  artWalletAddress?: string;
  artSmartContractAddress?: string;
  profilPictureIpfs?: string;
};
type UserStateInterface =
  | {
      userId: undefined;
      isOrganizationAdmin: boolean;
      isArtist: boolean;
      email: undefined;
      firstName: undefined;
      lastName: undefined;
      exp: undefined;
      token: undefined;
      signPrivateKey: undefined;
      cryptPrivateKey: undefined;
      cryptPublicKey: undefined;
      artWalletMnemonic: undefined;
      artWalletAddress: undefined;
      artSmartContractAddress: undefined;
      profilPictureIpfs: undefined;
    }
  | SessionLocal;

const initialState: UserStateInterface = {
  userId: undefined,
  isOrganizationAdmin: false,
  isArtist: false,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  exp: undefined,
  token: undefined,
  signPrivateKey: undefined,
  cryptPrivateKey: undefined,
  cryptPublicKey: undefined,
  artWalletMnemonic: undefined,
  artWalletAddress: undefined,
  artSmartContractAddress: undefined,
  profilPictureIpfs: undefined,
};

type Action =
  | (SessionLocal & { type: "SET_LOGGED_USER" })
  | { type: "REMOVE_LOGGED_USER" };

const mainReducer = (currentState: UserStateInterface, action: Action) => {
  switch (action.type) {
    case "SET_LOGGED_USER":
      const { type: _, ...newState } = action;
      return newState;
    case "REMOVE_LOGGED_USER":
      return initialState;
  }
};

const UserContext = createContext<{
  state: UserStateInterface;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const UserContextProvider = ({
  children,
  storage,
}: {
  children: JSX.Element;
  storage: Storage;
}) => {
  const loadedState = loadState(storage);
  const [state, dispatch] = useReducer(mainReducer, loadedState);
  useEffect(() => {
    storage.setItem("angelia", JSON.stringify(state));
  }, [state, storage]);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
const StringOrNull = (value: any) =>
  value === undefined ? undefined : String(value);
const loadState = (storage: Storage) => {
  const data = storage.getItem("angelia");
  if (data && data !== "{}") {
    return pick(JSON.parse(data), {
      userId: Number,
      exp: Number,
      token: String,
      signPublicKey: String,
      signPrivateKeyCrypted: String,
      signPrivateKey: String,
      cryptPublicKey: String,
      cryptPrivateKeyCrypted: String,
      cryptPrivateKey: String,
      artWalletMnemonic: StringOrNull,
      artWalletAddress: StringOrNull,
      artSmartContractAddress: StringOrNull,
      profilPictureIpfs: StringOrNull,
      email: String,
      firstName: String,
      lastName: String,
      isOrganizationAdmin: Boolean,
      isArtist: Boolean,
    });
  }
  return initialState;
};

type Descriptor<T> = {
  [P in keyof T]: (v: any) => T[P];
};

function pick<T>(v: any, d: Descriptor<T>): T {
  const ret: any = {};
  for (let key in d) {
    try {
      const val = d[key](v[key]);
      if (typeof val !== "undefined") {
        ret[key] = val;
      }
    } catch (err) {
      const msg = err instanceof Error ? err.message : String(err);
      throw new Error(`could not pick ${key}: ${msg}`);
    }
  }
  return ret;
}

export default UserContext;

import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import UserContext from "./AppRoot/context";

export const ProtectedRoute = () => {
  if (!useIsLogged()) {
    return (
      <Navigate
        to="/login"
        replace
        state={{
          snackBarMessage: {
            message: "Vous êtes déconnecté.",
            severity: "warning",
          },
        }}
      />
    );
  }
  return <Outlet />;
};

export const AntiProtectedRoute = () => {
  if (useIsLogged()) {
    return (
      <Navigate
        to="/"
        replace
        state={{
          snackBarMessage: {
            message: "Vous êtes déjà connecté.",
            severity: "warning",
          },
        }}
      />
    );
  }
  return <Outlet />;
};

export const useIsLogged = () => {
  const {
    state: { exp },
  } = useContext(UserContext);
  return !!exp && exp >= Date.now() / 1000;
};

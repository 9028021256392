import "./index.css";

import ReactDOM from "react-dom/client";

import AppRoot from "./App/AppRoot";
import MainApp from "./App/MainApp";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<AppRoot MainApp={MainApp} storage={sessionStorage} />);

export const toUtf8Hex = (str: string) =>
  new TextEncoder()
    .encode(str)
    .reduce((acc, byte) => acc + byte.toString(16).padStart(2, "0"), "");

export const fromUtf8Hex = (hex: string) =>
  new TextDecoder().decode(hexToBytes(hex));
const hexToBytes = (hex: string) => {
  const bytes = new Uint8Array(hex.length / 2);
  for (let i = 0, j = 0; i < hex.length; i += 2, j++) {
    bytes[j] = parseInt(hex.slice(i, i + 2), 16);
  }
  return bytes;
};

export const toBase64 = (str: string) =>
  btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
export const fromBase64 = (base64: string) =>
  atob(base64.replace(/-/g, "+").replace(/_/g, "/"));

export const ipfsToHttp = (str: string) =>
  str.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");

const EXTENSIONS = {
  image: ".jpg",
  metadata: "",
};
const FOLDERS = {
  image: "art_pictures",
  metadata: "art_metadata",
};

export const ipfsToBucket = (str: string, type: "image" | "metadata") =>
  str
    .replace(
      "ipfs://",
      `https://storage.googleapis.com/data-file-staging-bones/${FOLDERS[type]}/`
    )
    .replace("/metadata", "") + EXTENSIONS[type];

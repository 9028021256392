import { Backdrop } from "@mui/material";

import { Licorn } from "./LottieComponent/LottieAnimations";

const Loading = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Licorn />
    </Backdrop>
  );
};

export default Loading;

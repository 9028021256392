import { useCallback, useContext, useMemo, useState } from "react";

import UserContext from "../../AppRoot/context";

const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const closeMenu = useCallback(() => {
    setAnchorEl(undefined);
  }, []);
  return { anchorEl, openMenu, closeMenu };
};

const useLogout = (closeMenu: () => void) => {
  const {
    dispatch,
    state: { firstName, lastName, profilPictureIpfs },
  } = useContext(UserContext);
  const handleLogout = useCallback(() => {
    dispatch({ type: "REMOVE_LOGGED_USER" });
    closeMenu();
  }, [dispatch, closeMenu]);
  const initials = useMemo(
    () =>
      !!firstName && !!lastName
        ? firstName.charAt(0) + lastName.charAt(0)
        : "U",
    [firstName, lastName]
  );
  return { handleLogout, initials, profilPictureIpfs };
};

export { useMenu, useLogout };

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { UserContextProvider } from "./context";
import theme from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 30 * (60 * 1000),
      staleTime: 20 * (60 * 1000),
    },
  },
});

const AppRoot = ({
  MainApp,
  storage,
}: {
  MainApp: () => JSX.Element;
  storage: Storage;
}) => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <UserContextProvider storage={storage}>
                <MainApp />
              </UserContextProvider>
            </ThemeProvider>
          </Box>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default AppRoot;

import { useLogout, useMenu } from "./container";
import AccountMenuView from "./view";

const AccountMenu = () => {
  const { anchorEl, openMenu, closeMenu } = useMenu();
  const { handleLogout, initials, profilPictureIpfs } = useLogout(closeMenu);
  return (
    <AccountMenuView
      {...{
        initials,
        profilPictureIpfs,
        anchorEl,
        openMenu,
        closeMenu,
        handleLogout,
      }}
    />
  );
};

export default AccountMenu;

import "dayjs/locale/fr";

import { lazy, Suspense, useContext, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Footer, NavMenu } from "@bones-app/react-nav-menu";
import { Container } from "@mui/material";

import Loading from "../components/Loading";
import UserContext from "./AppRoot/context";
import {
  AntiProtectedRoute,
  ProtectedRoute,
  useIsLogged,
} from "./RestrictedRoutes";
import RightNav from "./RightNav";

const MentionsLegales = lazy(() =>
  import("@bones-app/react-nav-menu").then((module) => {
    return { default: module.MentionsLegales };
  })
);
const PrivacyPolicy = lazy(() =>
  import("@bones-app/react-nav-menu").then((module) => {
    return { default: module.PrivacyPolicy };
  })
);
const Login = lazy(() => import("../views/user/Login"));
const RegisterUser = lazy(() => import("../views/user/RegisterUser"));
const RegisterArtist = lazy(() => import("../views/user/RegisterArtist"));
const SetPassword = lazy(() => import("../views/user/SetPassword"));
const Dashboard = lazy(() => import("../views/Dashboard"));
const ArtworkList = lazy(() => import("../views/ArtworkList"));
const Artwork = lazy(() => import("../views/Artwork"));
const ArtistPage = lazy(() => import("../views/ArtistPage"));

const PAGE_LIST = [{ link: { pathname: "/artworks" }, label: "Mes œuvres" }];

const MainApp = () => {
  const location = useLocation();
  const isLogged = useIsLogged();
  const {
    state: { artSmartContractAddress },
  } = useContext(UserContext);
  const pageList = useMemo(
    () =>
      artSmartContractAddress
        ? [
            ...PAGE_LIST,
            {
              link: { pathname: `/artistpage/${artSmartContractAddress}` },
              label: "Mon profil",
            },
          ]
        : [...PAGE_LIST],
    [artSmartContractAddress]
  );
  const hideNavMenu =
    ["/login", "/register", "/register/artist"].includes(location.pathname) ||
    location.pathname.startsWith("/password") ||
    (location.pathname.startsWith("/artwork/") && !isLogged);

  return (
    <>
      {!hideNavMenu && <NavMenu pageList={pageList} RightNav={RightNav} />}
      <Container
        maxWidth={false}
        component="main"
        sx={{ paddingY: 2 }}
        disableGutters
      >
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path="/artistpage/:artSmartContractAddress"
              element={<ArtistPage />}
            />
            <Route path="/mentions_legales" element={<MentionsLegales />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/artwork/:tokenIdBase64" element={<Artwork />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/artworks" element={<ArtworkList />} />
              <Route path="*" element={<Dashboard />} />
            </Route>
            <Route element={<AntiProtectedRoute />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register/artist" element={<RegisterArtist />} />
              <Route path="/register" element={<RegisterUser />} />
              <Route path="/password/:token" element={<SetPassword />} />
            </Route>
          </Routes>
        </Suspense>
      </Container>
      <Footer pageList={pageList} />
    </>
  );
};

export default MainApp;

import { useSnackBarAlert } from "@bones-app/react-snack-bar-alert";

import { useIsLogged } from "../RestrictedRoutes";
import RightNavView from "./view";

const RightNav = () => {
  const isLogged = useIsLogged();
  const { currentSnack, handleClose, open } = useSnackBarAlert();
  return (
    <RightNavView
      isLogged={isLogged}
      snackBarMessageProps={{
        snackBarMessage: currentSnack,
        open,
        handleClose,
      }}
    />
  );
};

export default RightNav;

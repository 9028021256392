import Lottie from "react-lottie-player";

import LicornAnimate from "./animationsJSON/licorn.json";
import TrashAnimate from "./animationsJSON/trash.json";

export const Trash = () => {
  return (
    <div>
      <Lottie
        loop
        animationData={TrashAnimate}
        play
        style={{ width: 100, height: 100 }}
      />
    </div>
  );
};

export const Licorn = () => {
  return (
    <div>
      <Lottie
        loop
        animationData={LicornAnimate}
        play
        style={{ width: 300, height: 300 }}
      />
    </div>
  );
};

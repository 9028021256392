import { Avatar, SxProps, Theme } from "@mui/material";

import { ipfsToBucket } from "./utils";

const ArtistAvatar = ({
  profilPictureIpfs,
  initials,
  sxProps,
}: {
  profilPictureIpfs?: string;
  initials?: string;
  sxProps?: SxProps<Theme>;
}) => {
  return (
    <Avatar sx={sxProps} src={ipfsToBucket(profilPictureIpfs || "", "image")}>
      {initials}
    </Avatar>
  );
};

export default ArtistAvatar;

import LogoutIcon from "@mui/icons-material/Logout";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import ArtistAvatar from "../../../components/ArtistAvatar";

const AccountMenuView = ({
  initials,
  profilPictureIpfs,
  anchorEl,
  openMenu,
  closeMenu,
  handleLogout,
}: {
  initials: string;
  profilPictureIpfs?: string;
  anchorEl?: HTMLElement;
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
  handleLogout: () => void;
}) => {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Se déconnecter
        </MenuItem>
      </Menu>
      <Tooltip title="Mon compte">
        <IconButton onClick={openMenu} size="small" sx={{ ml: 2 }}>
          <ArtistAvatar
            {...{
              initials,
              profilPictureIpfs,
              sxProps: { backgroundColor: "#18a4de" },
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AccountMenuView;
